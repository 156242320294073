<template lang="pug">
div(v-show='price >= 3000' ref='paypalMessage' class='py-3')
</template>

<script setup lang="ts">
const { $paypal } = useNuxtApp()
const log = useLogger('PAYPAL_MESSAGING')

const {
  price,
  pageType,
  isGrayscale = false,
} = defineProps<{
  price: number
  pageType: 'product' | 'cart'
  isGrayscale?: boolean
}>()

const paypalMessage = ref(null)

onMounted(() => {
  renderMessage()
})

watch(
  () => price,
  (newVal, oldVal) => {
    if (newVal !== oldVal) renderMessage()
  }
)

async function renderMessage() {
  if (!window.paypal) await $paypal.display.load()

  if (!window.paypal.Messages) {
    log.error('Paypal messaging display error')
    return
  }

  window.paypal
    .Messages({
      amount: price / 100,
      placement: pageType,
      style: {
        layout: 'text',
        logo: {
          type: isGrayscale ? 'none' : 'primary',
          position: 'left',
        },
        text: {
          color: isGrayscale ? 'grayscale' : 'black',
          size: 14,
        },
      },
    })
    .render(paypalMessage.value)
}
</script>
